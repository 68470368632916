.selectableText {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

ion-header > ion-toolbar > ion-icon.circleIconButton {
 border-radius: 50%;
 padding: 2px;
 background: var(--ion-color-tertiary-light);
 color: var(--ion-color-tertiary-contrast)
}

 ion-icon.circleIconButton {
  border-radius: 50%;
  padding: 2px;
  background: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast)
}

ion-popover.select-popover .popover-content {
  width: 80%;
}

ion-col {
  text-align: center;
  margin: auto;
}

ion-text.lastOrNextPeriodDay {
  font-size: 18px;
  color: var(--ion-text-color)
}

.react-calendar {
  border-color: var(--ion-color-tertiary) !important;
}

.react-calendar .react-calendar__tile {
  color:  var(--ion-color-tertiary);
}

.react-calendar .react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}


.react-calendar .react-calendar__navigation__label {
  background-color: var(--ion-color-tertiary) !important;
  color: var(--ion-color-tertiary-contrast) !important;
}

.react-calendar .react-calendar__navigation__arrow {
  color: var(--ion-color-tertiary)
}

.react-calendar.periodTrackingCalendar .react-calendar__tile--active.notPeriodDay.react-calendar__month-view__days__day--neighboringMonth {
  background-color: white;
  color: #757575;
}

.react-calendar.periodTrackingCalendar .react-calendar__tile--active.notPeriodDay.react-calendar__month-view__days__day--weekend {
  background-color: white;
  color: #d10000;
}

.react-calendar.periodTrackingCalendar .react-calendar__tile--active.notPeriodDay.react-calendar__tile--now {
  background-color: #ffff76;
  color: black;
}

.react-calendar.periodTrackingCalendar .react-calendar__tile--active.notPeriodDay {
  background-color: white;
  color: black;
}

.react-calendar.periodTrackingCalendar .periodDay {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.react-calendar.periodTrackingCalendar .react-calendar__tile--active.periodDay {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.react-calendar.periodTrackingCalendar .react-calendar__tile.notPeriodDay {
  color:  var(--ion-color-tertiary);
}

.react-calendar.periodTrackingCalendar .react-calendar__month-view__days__day--neighboringMonth.notPeriodDay {
  color: #757575;
}


.title-large {
  font-size: 40px;
  color: var(--ion-color-primary);
}

.title-small {
  font-size: 24px;
}

.cardTitle {
  font-size: 18px;
}

ion-router-link {
  color: var(--ion-color-tertiary)
}

div#storesTableContainer {
  width: 100%;
  font-size: 14px;
  overflow-x: scroll !important;
}

div#storesTableContainer ion-text {
  color: black;
}

ion-loading ion-backdrop {
  --backdrop-opacity: 0.95;
  background-color: var(--ion-color-primary)
}

ion-modal ion-text {
  color: var(--ion-text-color);
}

ion-modal ion-icon {
  color: var(--ion-text-color);
}

ion-menu {
  --ion-text-color: var(--ion-color-tertiary-light);
  --ion-text-color-rgb: var(--ion-color-tertiary-light-rgb)
}

ion-grid.contentGrid {
  background-color: #FEF4F9;
  border-radius: 10px;
}

ion-toast::part(container) {
  background: var(--ion-color-primary)
}

ion-toast::part(message) {
  font-size: 18px;
  line-height: 20px;
}

ion-toast::part(header) {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}

ion-toast::part(button) {
  color: var(--ion-color-tertiary-contrast);
  background-color: var(--ion-color-tertiary);
}

ion-slide {
  padding-bottom: 30px;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

ion-card {
  border-radius: 5px;
  box-shadow: none;
  border: solid 2px var(--ion-color-primary);
  color: var(--ion-text-color);
}

ion-card.videoCard {
  /* padding: 5px; */
}

ion-card.videoCard > ion-card-content {
  padding: 0px;
}

ion-card.videoCard > ion-card-content > ion-grid {
  padding: 0px;
}

ion-card.dashboardContainer {
  padding: 5px;
}

ion-card.dashboardContainer ion-title {
  font-size: 18px;
}

ion-card.dashboardContainer ion-text {
  font-size: 16px;
}

ion-checkbox::part(container) {
  border-color: var(--ion-color-tertiary);
}

ion-slide ion-card {
  border-radius: 10px;
}

ion-slide ion-card-content {
  padding-top: 3px;
}

ion-slide ion-card-content .doctor-info-container {
  height: 225px;
  padding-top: 3px;
}

iframe {
  border-radius: 5px;
}

ion-button {
  --border-radius: 3px;
  text-transform: none;
}

ion-button.button-solid::part(native) {
  background-color: var(--ion-color-tertiary);
}

ion-button.padButton {
  width: 125px;
}

ion-button.verificationButton {
  width: 235px;
  height: 43px;
}

ion-button.myHealthButton {
  width: 300px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

ion-input, ion-textarea {
  border: solid 1px var(--ion-color-tertiary);
  border-radius: 3px;
  --background: var(--ion-color-primary-contrast) !important;
}

ion-select {
  border-radius: 3px;
  background-color: var(--ion-color-tertiary)
}

ion-select::part(icon), ion-select::part(placeholder), ion-select::part(text) {
  color: var(--ion-color-tertiary-contrast);
  opacity: 1;
}

ion-tab-button.functionalTabButton {
  color: var(--ion-color-tertiary);
}

ion-tab-button.functionalTabButton.followButton > ion-icon {
  border-radius: 50%;
  border: 3px solid var(--ion-color-tertiary);
  padding: 3px;
  /* background-color: purple; */
}

ion-tab-bar > ion-tab-button.tab-selected {
  color: var(--ion-color-primary);
}

ion-tab-bar > ion-tab-button {
  color: var(--ion-color-tertiary-light);
}

ion-menu-button {
  color: var(--ion-color-tertiary-light);
}

ion-list#messagesList {
  height: 55vh;
  overflow-y: scroll;
  flex-direction: column-reverse;
  display: flex;
  margin-top: 10px;
}

#messagesContainer > ion-item > ion-label > ion-text.dateTime {
  font-size: 13px;
  color: var(--ion-text-color);
  line-height: 13px;
}

#messagesContainer > ion-item > ion-label > ion-text.sender {
  font-size: 13px;
  color: var(--ion-color-primary);
  line-height: 13px;
}
#messagesContainer > ion-item > ion-label > ion-text.content {
  font-size: 16px;
  line-height: 16px;
  color: var(--ion-text-color);
}

#messagesContainer > ion-item > ion-label {
  margin-top: 7px;
  margin-bottom: 7px;
}

/* #For Radio Options */
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

/* #For Checkbox */
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-tappable.alert-checkbox {
  height: auto;
  contain: content;
}

ion-datetime {
  border: solid 2px var(--ion-color-tertiary);
  border-radius: 3px;
}

select#countrySelector, select#regionSelector {
  width: 50%;
  border: solid 2px var(--ion-color-tertiary);
  border-radius: 3px;
}
